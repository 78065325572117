import React, { useState, useEffect } from 'react';
import {getAreaWord} from "../../utils/areaWord";
import style from "./SelectedSegments.module.css"
import {useDispatch, useSelector} from "react-redux";
import xButtonIco from "../../common/icons/xButtonGrey.svg"
import {useModal} from "../../hook/useModal";
import ModalContent from "../Modal/ModalContent/ModalContent";
import {useClearAll} from "../../hook/useClearAll";
import {cancelAllPendingRequests} from "../../api/api";
import {
    resetRelatedINNs,
    resetSearchOrgINNINNs,
    resetSearchSuppINNINNINNs, resetSelectedComboItem, resetSelectedOrganization,
    resetSelectedSuggestion, resetSelectedValue, setRelatedINNs, setSelectedOrganization
} from "../../service/reducers/organizationSlice";
import {clearPosition, setTogglePosition} from "../../service/reducers/searchSwticherSlice";
import {resetOkpdCode} from "../../service/reducers/selectedComboOkpd";

const SelectedSegmentsDisplay = () => {
    const selectedPieSlice = useSelector((state) => state.pie.selectedSlice);
    const selectedRegions = useSelector((state) => state.region.activeRegions);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const contratTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const selectedOKPD = useSelector((state) => state.productCode.selectedProduct);
    const pieRoles = useSelector(state => state.donutRolesSlice.selectedSegments)
    const { currentActiveButton, selectedMonth, selectedTimePeriods } = useSelector((state) => state.barLineChartMonth);
    const currentActiveButtonV1 = useSelector(state => state.contractMonth1Slice.currentActiveButton)
    const selectedContractMonthV1 = useSelector(state => state.contractMonth1Slice.selectedContractMonth)
    const selectedTimePeriodsV1 = useSelector(state => state.contractMonth1Slice.selectedTimePeriods)
    const selectedMonthOrTimePeriodsCountV1 = currentActiveButtonV1 === 'month' ? selectedContractMonthV1.length : selectedTimePeriodsV1.length;
    const selectedContractOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const [isVisible, setIsVisible] = useState(false);
    const { setIsModalVisible, setModalContent } = useModal();
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const selectedComboItem = useSelector(state => state.organization.selectedComboItem);
    const dispatch = useDispatch();
    const selectedMonthOrTimePeriodsCount = currentActiveButton === 'month' ? selectedMonth.length : selectedTimePeriods.length;
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const togglePosition = useSelector(state => state.searchSwitcher.position);
    const isToggleLocked = useSelector(state => state.searchSwitcher.isLocked);
    const isToggleVisible = useSelector(state => state.searchSwitcher.isToggleVisible);
    const clickedNodes = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const totalSelected = (selectedPieSlice ? selectedPieSlice.length : 0) +
        (trimCode ? trimCode.length : 0) +
        (clickedNodes ? clickedNodes.length : 0) +
        (selectedZoomableSegment ? selectedZoomableSegment.length : 0) +
        (contratTrimCode ? contratTrimCode.length : 0) +
        (selectedKbrSegments ? selectedKbrSegments.length : 0) +
        (selectedCountryLine ? selectedCountryLine.length : 0) +
        (selectedRegions ? selectedRegions.length : 0) +
        (selectedSegments ? selectedSegments.length : 0) +
        (selectedOKPD ? selectedOKPD.length : 0) +
        (selectedContractOkpd ? selectedContractOkpd.length : 0) +
        (pieRoles ? pieRoles.length : 0) +
        selectedMonthOrTimePeriodsCount +
        selectedMonthOrTimePeriodsCountV1;
    const areaWord = getAreaWord(totalSelected);
    const clearAll = useClearAll();
    const defaultUID = "00000000-0000-0000-0000-000000000000";

    const handleCloseClick = () => {
        cancelAllPendingRequests();
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(clearPosition());
        dispatch(resetSelectedSuggestion());
        clearAll()
        dispatch(setRelatedINNs(defaultUID));
        dispatch(setSelectedOrganization(defaultUID));
    };

    const handleComboClick = () => {
        cancelAllPendingRequests();
        dispatch(resetSelectedOrganization())
        dispatch(resetRelatedINNs())
        dispatch(resetSearchOrgINNINNs());
        dispatch(resetSearchSuppINNINNINNs());
        dispatch(resetSelectedComboItem());
        dispatch(resetSelectedValue())
        dispatch(resetOkpdCode());
        dispatch(resetSelectedSuggestion());
        clearAll()
        dispatch(setRelatedINNs(defaultUID));
        dispatch(setSelectedOrganization(defaultUID));
    }
    const handleClearAllClick = () => {
        cancelAllPendingRequests();
        clearAll();
        dispatch(setRelatedINNs(defaultUID));
        dispatch(setSelectedOrganization(defaultUID));
        setIsVisible(false);
    };
    const handleSelectedClick = () => {
        setModalContent(
            <ModalContent
                onClose={() => {
                    setIsModalVisible(false);
                }}
                areaWord={areaWord}
                contentMode="default"
            />
        );
        setIsModalVisible(true);
    };

    const handleCompanyClick = () => {
        setModalContent(
            <ModalContent
                onClose={() => {
                    setIsModalVisible(false);
                }}
                areaWord={areaWord}
                contentMode="company"
            />
        );
        setIsModalVisible(true);
    };

    const handleComboClickModal = () => {
        setModalContent(
            <ModalContent
                onClose={() => {
                    setIsModalVisible(false);
                }}
                areaWord={areaWord}
                contentMode="combo"
            />
        );
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (
            totalSelected > 0 ||
            searchOrgINNINNs.length > 0 ||
            searchSuppINNINNINNs.length > 0 ||
            (selectedComboItem && Object.keys(selectedComboItem).length > 0)
        ) {
            setIsVisible(true);
        } else {
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [totalSelected, searchOrgINNINNs, searchSuppINNINNINNs, selectedComboItem]);

    let displayValues = [];
    if (searchOrgINNINNs.length > 0 && searchSuppINNINNINNs.length > 0) {
        displayValues = searchOrgINNINNs;
    } else if (searchOrgINNINNs.length > 0) {
        displayValues = searchOrgINNINNs;
    } else if (searchSuppINNINNINNs.length > 0) {
        displayValues = searchSuppINNINNINNs;
    }
    const handleCustomerClick = () => {
        if (!isToggleLocked) {
            cancelAllPendingRequests();
            dispatch(setTogglePosition('customer'));
        }
    };

    const handleSupplierClick = () => {
        if (!isToggleLocked) {
            cancelAllPendingRequests();
            dispatch(setTogglePosition('supplier'));
        }
    };


    const truncateString = (str, num) => {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    };

    if (!isVisible) return null;

    return (
        <div className={`${style.container} ${isVisible ? style.visible : ''}`}>
            {selectedComboItem && Object.keys(selectedComboItem).length > 0 && (
                <div onClick={handleComboClickModal} className={style.selected}>
                    <span>
                        {'okpd_name' in selectedComboItem ? truncateString(selectedComboItem.okpd_name, 15) : 'label' in selectedComboItem ? truncateString(selectedComboItem.label, 15) : ''}
                    </span>
                    <img src={xButtonIco} alt="xIcon" onClick={(e) => {
                        e.stopPropagation();
                        handleComboClick();
                    }}/>
                </div>
            )}
            {displayValues.length > 0 && (
                <div className={style.selected} onClick={handleCompanyClick}>
                     <span>
                        {displayValues.map((value, index) => (
                            <div key={index}>
                                {value}
                            </div>
                        ))}
                     </span>
                    <img src={xButtonIco} alt="xIcon" onClick={(e) => {
                        e.stopPropagation();
                        handleCloseClick();
                    }}/>
                </div>
            )}
            {totalSelected > 0 && (
                <div onClick={handleSelectedClick} className={style.selected}>
                    <span>
                         Выбрано {totalSelected} {areaWord}
                     </span>
                    <img src={xButtonIco} alt="xIcon" onClick={(e) => {
                        e.stopPropagation();
                        handleClearAllClick();
                    }}/>
                </div>
            )}
            <div className={style.lastChild}>
                {isToggleVisible && (
                    <div className={`${style.toggleContainer}`}>
                        <button
                            className={`${style.toggleButton} ${togglePosition === 'customer' ? style.toggleButtonActive : ''}`}
                            onClick={handleCustomerClick}
                        >
                            Поставщик
                        </button>
                        <button
                            className={`${style.toggleButton} ${togglePosition === 'supplier' ? style.toggleButtonActive : ''}`}
                            onClick={handleSupplierClick}
                        >
                            Заказчик
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};


export default SelectedSegmentsDisplay;
