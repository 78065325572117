import React, {useEffect, useState} from 'react';
import xButtonIco from "../../common/icons/xButtonBlack.svg";
import {useDispatch, useSelector} from "react-redux";
import {fetchInfoData, resetInfoData} from "../../service/reducers/InfoStatistics";
import styles from './InfoTable.module.css';

export const InfoTable = ({onClose}) => {
    const dispatch = useDispatch();
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const {InfoData, loading } = useSelector((state) => state.info);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const top = useSelector((state) => state.activitySlice);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const {selectedSegments} = useSelector((state) => state.treeMapSlice);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const slidePoz = useSelector(state => state.searchSwitcher.position);


    const [displayedItemsCount, setDisplayedItemsCount] = useState(100);

    const loadMoreItems = () => {
        setDisplayedItemsCount(prevCount => prevCount + 100);
    };

    useEffect(() => {
        const requestData = {
        relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            trimCode,
            selectedSegments,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedCountryLine,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        }
        dispatch(fetchInfoData(requestData));
        return () => {
            dispatch(resetInfoData());
            setDisplayedItemsCount(100)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('ru-RU', options);
    }

    const renderInfoData = () => {
        return InfoData.slice(0, displayedItemsCount).map((info, index) => (
            <div key={index} className={styles.container}>
                <div className={styles.row}>
                    <div className={styles.content}>
                        <div className={styles.label}><strong>Название:</strong></div>
                        <div className={styles.value}>{info.label}</div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.label}><strong>Стоимость:</strong></div>
                        <div className={styles.value}>{info.value}</div>
                    </div>
                    {info.extra.filter(item => item.label !== 'count').map((item, idx) => (
                        <div key={idx} className={styles.content}>
                            <div className={styles.label}>
                                <strong>
                                    {item.label === 'StartDT' || item.label === 'ContractSignDate' ? 'Дата' : item.label}:
                                </strong>
                            </div>
                            <div className={styles.value}>
                                {item.label === 'StartDT' || item.label === 'ContractSignDate' ? formatDate(item.value) : item.value}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.header}>
                {loading === 'successful' && (
                    <div className={styles.legendAddition}>
                        Отображено {Math.min(displayedItemsCount, InfoData.length)} из {InfoData.length} строк
                    </div>
                )}
                <div onClick={onClose}>
                    <img src={xButtonIco} alt="closeXIco" className={styles.closeIcon} />
                </div>
            </div>
            <div className={styles.dataContainer}>
                {loading === 'successful' && renderInfoData()}
                {loading === 'successful' && displayedItemsCount < InfoData.length && (
                        <div onClick={loadMoreItems} className={styles.buttondAddition}>
                            Показать ещё
                        </div>
                )}
            </div>
        </div>
    );
}
