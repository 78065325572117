import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Autocomplete, TextField, ToggleButton, ToggleButtonGroup} from '@mui/material';
import style from './GourpWizzard.module.css';
import styles from '../../EditFormGlobal/EditForm.module.css';
import btnStyle from '../../CustomModalChanger/CustomModal.module.css';
import {
    addGroup, addOkpd,
    addUser,
    cancelAllPendingRequests, deleteInGroup, deleteOkpd,
    deleteUser,
    searchCompanies,
    submitStepOne
} from "../../../api/api";
import {setStep} from "../../../service/reducers/WizardSlice";
import trash from "../../../common/icons/trashIcon.svg";
import SearchAutocomplete from "../../SearchAutoComplete/SearchAutoComplete";
import {fetchOkpdUserData} from "../../../service/reducers/userOkpdListSlice";
import CircularProgress from '@mui/material/CircularProgress';

const GroupWizard = ({ onReset, onClose }) => {
    const [groupName, setGroupName] = useState('');
    const [selectedOkpd, setSelectedOkpd] = useState([]);
    const [selectedType, setSelectedType] = useState('company');
    const [selectedCompanyType, setSelectedCompanyType] = useState('contractor');
    const wizardStep = useSelector((state) => state.wizardStep.currentStep);
    const dispatch = useDispatch();
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [groupId, setGroupId] = useState('');
    const usersData = useSelector((state) => state.usersData.usersData);
    const meData = useSelector((state) => state.meData.meData);
    const [selectedUser, setSelectedUser] = useState([]);
    const filteredUsers = usersData.filter(user => user.id !== meData.id);
    const [confirmModal, setConfirmModal] = useState({ open: false, data: null, type: null });
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData);
    const [inputValue, setInputValue] = useState('');
    const companyUserData = useSelector((state) => state.companyUserData.companyUserData);
    const [defaultCompanyOptions, setDefaultCompanyOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const okpdDataList = okpdListData || [];

    useEffect(() => {
        const filteredCompanies = companyUserData.filter(company => {
            return selectedCompanyType === 'contractor' ? company.suppliers : company.customer;
        });
        setDefaultCompanyOptions(filteredCompanies.map(c => `${c.short_name} (${c.inn})`));
    }, [selectedCompanyType, companyUserData]);

    useEffect(() => {
        dispatch(fetchOkpdUserData());
    }, [dispatch]);
    const resetLocalState = () => {
        setGroupName('');
        setSelectedType('company');
        setSelectedCompanyType('contractor');
        setSelectedCompanies([]);
        setSearchResults([]);
        setGroupId('');
        setInputValue('')
        setSelectedUser([]);
        setSelectedOkpd([]);
    };

    useEffect(() => {
        resetLocalState()
// eslint-disable-next-line
    },[onReset])

    const handleWizardNext = async () => {
        if (wizardStep === 1) {
            let type = '';

            if (selectedType === 'company') {
                type = selectedCompanyType === 'contractor' ? 'company_suppliers' : 'company_customer';
            } else if (selectedType === 'okpd') {
                type = 'okpd';
            }

            const data = {
                label: groupName,
                type: type
            };

            if (data.label && data.type) {
                try {
                    setIsLoading(true);
                    const response = await submitStepOne(data);
                    if (response.success) {
                        setIsLoading(false);
                        setGroupId(response.result.id);
                        dispatch(setStep(wizardStep + 1));
                    }
                } catch (error) {
                    console.error('Ошибка при отправке данных:', error);
                }
            }
        } else if (wizardStep === 2) {
            dispatch(setStep(wizardStep + 1));
        }
    };

    const handleWizardComplete = () => {
        if (wizardStep === 3) {
            if (onClose) {
                onClose();
            }
        } else {
            handleWizardNext();
        }
    };
    const handleSearchChange = async (event, newInputValue) => {
        cancelAllPendingRequests();
        setInputValue('')
        if (newInputValue.length > 0) {
            try {
                const results = await searchCompanies(newInputValue);
                let filteredResults = [];

                if (results.length > 0) {
                    if (selectedCompanyType === 'customer') {
                        filteredResults = results.filter(company => company.customer);
                    } else if (selectedCompanyType === 'contractor') {
                        filteredResults = results.filter(company => company.suppliers);
                    } else {
                        filteredResults = results;
                    }

                    setSearchResults(filteredResults.slice(0, 30));
                } else {
                    setSearchResults([{ short_name: 'Ничего не найдено', id: 'not_found' }]);
                }
            } catch (error) {
                console.error('Ошибка при выполнении поиска:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        handleSearchChange(event, newInputValue);
    };

    const submitCompanyData = async (inn) => {
        const data = {
            group_id: groupId,
            inn: inn
        };

        try {
            setIsLoading(true);
            await addGroup(data)
            setIsLoading(false);
        } catch (error) {
            console.error('Ошибка при отправке данных компании:', error);
        }
    };

    const handleDeletInn = async (company) => {
        const inn = extractInn(company);
        const innData = {
            group_id: groupId,
            inn: inn,
        };

        try {
            await deleteInGroup(innData);
            setSelectedCompanies(currentCompanies => currentCompanies.filter(c => !c.includes(`(${inn})`)));
        } catch (error) {
            console.error('Ошибка при удалении пользователя:', error);
        }
    };

    const submitUserData = async (user) => {
        const userData = {
            group_id: groupId,
            user_id: user
        };

        try {
            setIsLoading(true);
            await addUser(userData)
            setIsLoading(false);
        } catch (error) {
            console.error('Ошибка при отправке данных компании:', error);
        }
    }

    const handleDeleteUser = async (user) => {
        const userData = {
            group_id: groupId,
            user_id: user.id
        };

        try {
            await deleteUser(userData);
            setSelectedUser(currentSelectedUser => currentSelectedUser.filter(u => u.id !== user.id));
        } catch (error) {
            console.error('Ошибка при удалении пользователя:', error);
        }
    };

    const handleUserChange = (event, newValue) => {
        if (newValue && !selectedUser.some(user => user.id === newValue.id)) {
            setSelectedUser([...selectedUser, newValue]);
            submitUserData(newValue.id);
        }
    };

    const extractInn = (newValue) => {
        const match = newValue.match(/\((\d+)\)/);
        return match ? match[1] : null;
    };
    const handleSelectCompany = (event, newValue, reason) => {
        if (reason === 'selectOption') {
            if (!selectedCompanies.includes(newValue)) {
                setSelectedCompanies([...selectedCompanies, newValue]);
                const inn = extractInn(newValue);
                if (inn) {
                    submitCompanyData(inn);
                }
            }
            setInputValue("");
        }
    };

    const showConfirmModal = (data, type) => {
        setConfirmModal({ open: true, data, type });
    };


    const hideConfirmModal = () => {
        setConfirmModal({ open: false, data: null });
    };

    const confirmDelete = async () => {
        switch (confirmModal.type) {
            case 'okpd':
                await handleDeleteOkpd(confirmModal.data);
                break;
            case 'company':
                await handleDeletInn(confirmModal.data);
                break;
            case 'user':
                await handleDeleteUser(confirmModal.data);
                break;
            default:
                console.error('Неизвестный тип для удаления');
        }
        hideConfirmModal();
    };
    const handleOkpdChange = async (event, newValue) => {
        if (newValue) {
            const newOkpdLevel = newValue.level;
            const isLevelMatch = selectedOkpd.every((okpd) => okpd.level === newOkpdLevel);

            if (isLevelMatch && !selectedOkpd.some(okpd => okpd.okpd_code === newValue.okpd_code)) {
                try {
                    const okpdData = {
                        group_id: groupId,
                        okpd: newValue.okpd_code
                    };
                    setIsLoading(true);
                    await addOkpd(okpdData);
                    setIsLoading(false);
                    setSelectedOkpd([...selectedOkpd, newValue]);
                } catch (error) {
                    console.error('Ошибка при добавлении ОКПД:', error);
                }
            }
        }
    };
    const handleDeleteOkpd = async (okpd) => {
        try {
            await deleteOkpd({ group_id: groupId, okpd: okpd.okpd_code });
            setSelectedOkpd(currentOkpd => currentOkpd.filter(item => item.okpd_code !== okpd.okpd_code));
        } catch (error) {
            console.error('Ошибка при удалении ОКПД:', error);
        }
    };

    return (
        <div>
            {wizardStep === 1 && (
                <div>
                    <div className={styles.formControl}>
                        <div>
                            Выберите тип группы
                        </div>
                        <ToggleButtonGroup
                            value={selectedType}
                            exclusive
                            onChange={(e, newValue) => {
                                setSelectedType(newValue);
                                setSelectedCompanyType('');
                            }}
                            style={{ fontFamily: 'Golos Regular', fontSize: '14px', marginTop: '5px', marginBottom: '5px', height: '24px' }}
                        >
                            <ToggleButton
                                value="company"
                                className={`${style.toggleButton} ${selectedType === 'company' ? style.active : ''}`}
                            >
                                Компания
                            </ToggleButton>
                            <ToggleButton
                                value="okpd"
                                className={`${style.toggleButton} ${selectedType === 'okpd' ? style.active : ''}`}
                            >
                                ОКПД
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {selectedType === 'company' && (
                            <div>
                                <div>
                                    Выберите тип группы
                                </div>
                                <ToggleButtonGroup
                                    value={selectedCompanyType}
                                    exclusive
                                    onChange={(e, newValue) => setSelectedCompanyType(newValue)}
                                    style={{ fontFamily: 'Golos Regular', fontSize: '14px', marginTop: '5px', height: '24px' }}
                                >
                                    <ToggleButton
                                        value="contractor"
                                        className={`${style.toggleButton} ${selectedCompanyType === 'contractor' ? style.active : ''}`}
                                    >
                                        Подрядчик
                                    </ToggleButton>
                                    <ToggleButton
                                        value="customer"
                                        className={`${style.toggleButton} ${selectedCompanyType === 'customer' ? style.active : ''}`}
                                    >
                                        Заказчик
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        )}
                    </div>
                    <span>
                        Введите название группы
                    </span>
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                backgroundColor: '#F7FAFC',
                                '& fieldset': {
                                    borderColor: '#8D96B2',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#C8A1FF',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#4A3DFD',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                            '& .MuiInputBase-input': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                            '& .MuiFormHelperText-root': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                        }}
                        className={styles.formControl}
                        name={groupName}
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </div>
            )}
            {wizardStep === 2 && (
                <div>
                    {selectedType === 'company' && selectedCompanyType === 'contractor' && (
                        <>
                            <div className={styles.formControl}>
                                Найдите и выберите Компанию Подрядчика
                            </div>
                            <SearchAutocomplete
                                options={[...defaultCompanyOptions, ...searchResults.map(option => `${option.short_name} (${option.inn})`)]}
                                onChange={handleSelectCompany}
                                onInputChange={handleInputChange}
                                inputValue={inputValue}
                                placeholder="Введите название компании"
                                noOptionsText="Ничего не найдено"
                                value={inputValue}
                            />
                        </>
                    )}
                    {selectedType === 'company' && selectedCompanyType === 'customer' && (
                        <>
                            <div className={styles.formControl}>
                                Найдите и выберите Компанию Заказчика
                            </div>
                            <SearchAutocomplete
                                options={[...defaultCompanyOptions, ...searchResults.map(option => `${option.short_name} (${option.inn})`)]}
                                onChange={handleSelectCompany}
                                onInputChange={handleInputChange}
                                inputValue={inputValue}
                                placeholder="Введите название компании"
                                noOptionsText="Ничего не найдено"
                                value={inputValue}
                            />
                        </>
                    )}
                    {selectedType === 'okpd' && (
                        <>
                            <div className={styles.formControl}>
                                Найдите и выберите ОКПД
                            </div>
                            <Autocomplete
                                className="autocomplete-custom"
                                value={null}
                                onChange={handleOkpdChange}
                                options={okpdDataList}
                                getOptionLabel={(option) => `${option.okpd_code} ${option.okpd_name}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                                clearOnEscape
                                noOptionsText="Ничего не найдено"
                            />
                        </>
                    )}
                    {selectedOkpd.length > 0 && (
                        <div className={style.companiesContainer}>
                            {selectedOkpd.map((okpd, index) => (
                                <div className={style.companyMapContainer} key={index}>
                                    <div>{`${okpd.okpd_name} (${okpd.okpd_code})`}</div>
                                    <img
                                        className={style.trashIco}
                                        src={trash}
                                        alt="trash"
                                        onClick={() => showConfirmModal(okpd, 'okpd')}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {selectedCompanies.length > 0 && (
                        <div className={style.companiesContainer}>
                            {selectedCompanies.map((company, index) => (
                                <div className={style.companyMapContainer}>
                                    <div key={index}>{company}</div>
                                    <img
                                        className={style.trashIco}
                                        src={trash}
                                        alt="trash"
                                        onClick={() => showConfirmModal(company, 'company')}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {confirmModal.open && (
                        <div className={`${style.confirmModal} ${confirmModal.open ? style.confirmModalActive : ''}`}>
                            {confirmModal.type === 'okpd' && (
                                <p>Подтвердите удаление ОКПД {confirmModal.data.okpd_name} ({confirmModal.data.okpd_code})</p>
                            )}
                            {confirmModal.type === 'company' && (
                                <p>Подтвердите удаление компании {confirmModal.data}</p>
                            )}
                            {confirmModal.type === 'user' && (
                                <p>Подтвердите удаление пользователя {confirmModal.data.label}</p>
                            )}
                            <div className={style.confirmModalBtnGroup}>
                                <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`} onClick={confirmDelete}>Да</button>
                                <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton} ${btnStyle.redButton}`} onClick={hideConfirmModal}>Отмена</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {wizardStep === 3 && (
                <>
                    <div className={styles.formControl}>
                        Найдите и выберите пользователей
                    </div>
                    <Autocomplete
                        className="autocomplete-custom"
                        value={null}
                        onChange={handleUserChange}
                        options={filteredUsers}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        noOptionsText="Нет доступных пользователей"
                    />
                    {selectedUser.length > 0 && (
                        <div className={style.companiesContainer}>
                            {selectedUser.map((user, index) => (
                                <div className={style.companyMapContainer} key={index}>
                                    <div>{user.label}</div>
                                    <img
                                        className={style.trashIco}
                                        src={trash}
                                        alt="trash"
                                        onClick={() => showConfirmModal(user, 'user')}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {confirmModal.open && (
                        <div className={`${style.confirmModal} ${confirmModal.open ? style.confirmModalActive : ''}`}>
                            <p>Подтвердите удаление пользователя {confirmModal.data?.label}</p>
                            <div className={style.confirmModalBtnGroup}>
                                <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`} onClick={confirmDelete}>Да</button>
                                <button className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton} ${btnStyle.redButton}`} onClick={hideConfirmModal}>Отмена</button>
                            </div>
                        </div>
                    )}
                </>
            )}
            <button
                className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton}`}
                onClick={handleWizardComplete}
                disabled={isLoading}
                style={{ opacity: isLoading ? 0.5 : 1 }}>
                {wizardStep === 3 ? 'Завершить' : 'Далее'}
            </button>
            {isLoading && (
                <div className={btnStyle.sendingContainer}>
                    <CircularProgress size={20} />
                    <span>Данные отправляются...</span>
                </div>
            )}
        </div>
    );
};

export default GroupWizard;
