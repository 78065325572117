import dashIcon from "../../common/icons/DashGray.svg";
import dashIconDark from "../../common/icons/DashBlue.svg";
import searchIcon from "../../common/icons/SearchGray.svg";
import searchIconDark from "../../common/icons/SearchBlue.svg";
import rationIcon from "../../common/icons/RatioGray.svg";
import rationIconDark from "../../common/icons/RatioBlue.svg";

export const menuItemsTop = [
    {icon: dashIcon, darkIcon: dashIconDark, text: 'Дашборд', path: "/content/dashboard"},
    {icon: searchIcon, darkIcon: searchIconDark, text: 'Поиск Закупок', path: "/content/search"},
    {icon: rationIcon, darkIcon: rationIconDark, text: 'Рейтинги', path: "/content/ratings"},
];
